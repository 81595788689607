import React, { useEffect } from 'react';
import styled from "styled-components";
import confetti from "canvas-confetti";
import { useTelegram } from "app/providers";
import { useNavigate } from "react-router-dom";
import partyPopperImg from "shared/assets/party-popper.png";
import { Button, Stack } from "shared/ui";

export const TaskFinishPage = () => {
  const { tg } = useTelegram();
  const navigate = useNavigate();

  useEffect(() => {
    confetti({
      particleCount: 200,
      spread: 80
    });

    tg.BackButton.show();
    tg.BackButton.onClick(() => {
      navigate('/tasks')
    });

    return () => {
      tg.BackButton.hide();
    }
  }, [tg, navigate])

  return (
    <TaskFinishPageWrap>
      <div className="content">
        <div className="img">
          <img src={partyPopperImg} alt="party-popper"/>
        </div>
        <Stack gap="04">
          <h3>Отлично!</h3>
          <p className="section-title">Ты только что сделал дизайн-систему ЕФР<br/> ещё лучше, но это не точно.</p>
        </Stack>
        <p className="subtitle">Компонент находится на проверке. Если что-то<br/> будет не так — придёт уведомление</p>
      </div>
      <Button full onClick={() => navigate('/tasks')}>На главную</Button>
    </TaskFinishPageWrap>
  );
};

const TaskFinishPageWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: var(--spacing--06);
        font-size: 14px;
        min-height: calc(100vh - 48px);

        .img {
            width: 72px;
            height: 72px;

            img {
                width: 100%;
            }
        }

        h3 {
            color: var(--tg-theme-text-color);
            font-size: 26px;
            font-weight: 700;
            margin: 0;
        }

        p {
            margin: 0;
        }

        .section-title {
            color: var(--tg-theme-subtitle-text-color);
        }

        .subtitle {
            color: var(--tg-theme-subtitle-text-color);
        }    
    }
`