import { sample } from "effector";
import { createGate } from "effector-react";
import { fxGetTasks } from "entities/task";
import { filterForm } from "features/filter/model";

export const TasksGate = createGate();

/**
 * Запрос задач
 */
sample({
  source: filterForm.$values,
  clock: TasksGate.open,
  fn: ({status}) => ({
    status
  }),
  target: fxGetTasks
})