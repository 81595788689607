export const styles = {
  kind: {
    primary: {
      background: "var(--tg-theme-button-color)",
      color: "var(--tg-theme-button-text-color)"
    },
    secondary: {
      background: "#D1D1D6",
      color: "var(--tg-theme-title-text-color)"
    },
    danger: {
      background: "transparent",
      color: "var(--text-01)",
      disabled: "var(--disable-01)",
      hover: "var(--disable-01)",
      disabledColor: "var(--disable-02)"
    },
    tertiary: {
      background: "transparent",
      color: "var(--text-01)",
      disabled: "var(--disable-01)",
      hover: "var(--disable-01)",
      disabledColor: "var(--disable-02)"
    }
  },
  size: {
    sm: 'var(--spacing--06)',
    md: 'var(--spacing--09)'
  },
}
