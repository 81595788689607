import { sample } from "effector";
import { createForm } from "effector-forms";
import { fxGetTasks } from "entities/task";

export const filterForm = createForm({
  fields: {
    status: {
      init: "TODO",
    }
  }
})

sample({
  clock: filterForm.$values,
  fn: ({status}) => ({
    status
  }),
  target: fxGetTasks
})