import React, { useEffect } from 'react';
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { useGate, useUnit } from "effector-react";
import { useTelegram } from "app/providers";
import { $value, setValue, TaskRefinementGate, formSubmit } from "./model";
import { Button, Stack, TextArea, Title } from "shared/ui";
import { updateStatusTask } from "pages/task/model";
import { TaskStatus } from "shared/api";

export const TaskRefinementPage = () => {
  const {id} = useParams();
  useGate(TaskRefinementGate, id);

  const { tg } = useTelegram();
  const navigate = useNavigate();
  const [value] = useUnit([$value]);


  useEffect(() => {
    tg.BackButton.show();
    tg.BackButton.onClick(() => {
      navigate(`/task/${id}`)
    });

    return () => {

      tg.BackButton.hide();
    }
  }, [tg, navigate]);

  const onFormSubmit = () => {
    formSubmit();
    updateStatusTask(TaskStatus.IN_PROGRESS);
    navigate(`/task/${id}`)
  }

  return (
    <TaskRefinementPageWrap onSubmit={onFormSubmit}>
      <Stack gap="06">
        <Title>Доработка</Title>
        <TextArea placeholder="Укажи замечания" value={value} onChange={(e) => setValue(e.target.value)} required/>
      </Stack>
      <div className="footer">
        <Button full type="submit">Отправить на доработку</Button>
      </div>
    </TaskRefinementPageWrap>
  );
};

const TaskRefinementPageWrap = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    padding-top: var(--spacing--05);

    .footer {
        display: flex;
        gap: var(--spacing--06);
        margin: 0 calc(var(--spacing--05) * -1);
        border-top: 1px solid rgba(120, 120, 128, 0.08);
        padding: var(--spacing--03) var(--spacing--05) var(--spacing--06);
    }
`