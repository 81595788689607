import React from 'react'
import styled from 'styled-components'
import { IButton, Size, Kind } from './types'
import { styles } from './constants'

export const Button: React.FC<IButton> = ({
  className,
  children,
  size = 'md',
  full = false,
  kind = 'primary',
  disabled = false,
  icon,
  ...other
}) => {
  return (
    <ButtonWrap className={className} disabled={disabled} size={size} full={full} kind={kind} {...other}>
      {children}
      {icon && icon}
    </ButtonWrap>
  )
}

const ButtonWrap = styled.button<IButton>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: ${({full}) => full ? "100%" : "auto"};
    height: ${({size}) => styles.size[size as Size]};
    outline: none;
    border-radius: var(--spacing--03);
    padding: 0 var(--spacing--05);
    text-decoration: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
    white-space: nowrap;
    cursor: pointer;
    font-size: 17px;
    font-weight: 500;
    transition: all .2s;
    border: none;
    background-color: ${({kind}) => styles.kind[kind as Kind].background};
    color: ${({kind}) => styles.kind[kind as Kind].color};

    &:hover,
    &:focus {
        opacity: 0.8;
    }
`
