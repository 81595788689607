import React from "react";
import styled from "styled-components";

interface ITextArea extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

export const TextArea: React.FC<ITextArea> = (props) => {
  return (
    <TextAreaWrap {...props}/>
  );
};

const TextAreaWrap = styled.textarea`
    padding: var(--spacing--05);
    color: #000;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    border-radius: 6px;
    border: 1px solid #C8C7CB;
    resize: vertical;
    min-height: 160px;
`
