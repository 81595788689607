import React, { useEffect } from "react";
import styled from "styled-components";
import { IBaseComponent } from "shared/types";
import { useGate, useUnit } from "effector-react";
import { fxGetSession, $session, fxLogin } from "entities/user";
import { useTelegram } from "./useTelegram";
import { $isAuthState, AuthProviderGate } from "./model";

export const AuthProvider: React.FC<IBaseComponent> = ({children}) => {
  const { initDataUnsafe } = useTelegram();
  const [session, loading, loadingLogin, isAuthState] = useUnit([$session, fxGetSession.pending, fxLogin.pending, $isAuthState]);

  // @ts-ignore
  useGate(AuthProviderGate, initDataUnsafe);

  if(loading || loadingLogin) {
    return null
  }

  if(session) {
    return (
      <div>{children}</div>
    )
  }

  return (
    <AuthProviderWrap>Вы не авторизованы!</AuthProviderWrap>
  )
};

const AuthProviderWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-weight: bold;
    font-size: 16px;
`