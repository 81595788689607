import { createGate } from "effector-react";
import { createEvent, sample } from "effector";
import { $task, fxGetTask, fxUpdateTask } from "entities/task";
import { restore } from "effector/effector.umd";

export const TaskRefinementGate = createGate<string>();

sample({
  clock: TaskRefinementGate.open,
  fn: (id) => parseInt(id),
  target: fxGetTask
})

export const setValue = createEvent<string>();
export const formSubmit = createEvent();
export const $value = restore(setValue, "");

$task.reset(TaskRefinementGate.close);
$value.reset(TaskRefinementGate.close);

sample({
  clock: fxGetTask.doneData,
  fn: (data) => data.comments,
  target: $value
})

sample({
  source: [$value, TaskRefinementGate.state],
  clock: formSubmit,
  fn: ([value, id]) => ({
    id: parseInt(id),
    comments: value,
  }),
  target: fxUpdateTask
})