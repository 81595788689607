import React, {useEffect} from "react";
import styled from "styled-components";
import { useGate, useUnit } from "effector-react";
import { useNavigate, useParams } from "react-router-dom";
import { $session } from "entities/user";
import { $task, $taskPending } from "entities/task";
import { Title, Stack, Priority, DateTime, PropertyList, FigmaIcon, Button } from "shared/ui";
import { useTelegram } from "app/providers";
import { formatDateDMYHM } from "shared/lib";
import { TaskStatus } from "shared/api";
import { TaskGate, $props, startTask, updateStatusTask } from "./model";

export const TaskPage: React.FC = () => {
  const {id} = useParams();
  useGate(TaskGate, id);

  const { tg } = useTelegram();
  const navigate = useNavigate();
  const [task, $loading, props, session] = useUnit([$task, $taskPending, $props, $session]);
  console.log(task)
  useEffect(() => {
    tg.BackButton.show();
    tg.BackButton.onClick(() => {
      navigate('/tasks')
    });

    return () => {
      tg.BackButton.hide();
    }
  }, [tg, navigate])

  if($loading) {
    return null
  }

  if(!task) {
    return (
      <TaskPageWrap>
        404
      </TaskPageWrap>
    )
  }

  const onSubmitReview = () => {
    updateStatusTask(TaskStatus.REVIEW);
    navigate('/tasks/finish')
  }

  const onSubmit = () => {
    updateStatusTask(TaskStatus.DONE);
    navigate('/tasks')
  }

  const onSubmitRefinement = () => {
    navigate(`/task/${id}/refinement`)
  }

  return (
    <TaskPageWrap>
      <Stack gap="06">
        <Stack gap="04">
          <div className="header">
            <Priority level={task.priority}/>
            <DateTime date={formatDateDMYHM(task.createdDate)}/>
          </div>
          <Title>{task.title}</Title>
          <div className="description">{task.description}</div>
          {task.comments && (
            <div className="comments">
              <div className="comments-title">Доработка:</div>
              <div>{task.comments}</div>
            </div>
          )}
        </Stack>
        {task.figmaLink && (
          <div className="link-list">
            <a href={task.figmaLink} target="_blank" className="link">Открыть <FigmaIcon/></a>
          </div>
        )}
        <PropertyList items={props}/>
      </Stack>
      <div className="footer">
        {(task.createdById === session?.id && task.status === TaskStatus.REVIEW) && (
          <>
            <Button full kind="secondary" onClick={() => onSubmitRefinement()}>На доработку</Button>
            <Button full onClick={() => onSubmit()}>Принять</Button>
          </>
        )}
        {!task.assignedTo && <Button full onClick={() => startTask()}>Взять в работу</Button>}
        {(task.assignedToId === session?.id && task.status === TaskStatus.IN_PROGRESS) && <Button full onClick={onSubmitReview}>Отправить на проверку</Button>}
      </div>
    </TaskPageWrap>
  );
};

const TaskPageWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    padding-top: var(--spacing--05);

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: var(--spacing--03);
    }
    
    .footer {
        display: flex;
        gap: var(--spacing--06);
        margin: 0 calc(var(--spacing--05) * -1);
        border-top: 1px solid rgba(120, 120, 128, 0.08);
        padding: var(--spacing--03) var(--spacing--05) var(--spacing--06);
    }

    .description,
    .comments {
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--tg-theme-title-text-color);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.154px;
    }
    
    .comments-title {
        font-size: 13px;
        color: var(--tg-theme-subtitle-text-color);
        margin-bottom: var(--spacing--02);
    }

    .link {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        text-decoration: none;
        gap: var(--spacing--05);
        padding: var(--spacing--03);
        border-radius: var(--radius-02, 6px);
        border: 1px solid var(--Wallet-separator_color, #C8C7CB);
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        color: var(--tg-theme-title-text-color);
        letter-spacing: -0.08px;
    }
`