import { createGate } from "effector-react";
import { createStore, sample } from "effector";
import { getSession, fxGetSession, fxLogin, login } from "entities/user";
import { ILoginReq } from "shared/api";

export const AuthProviderGate = createGate<ILoginReq>();

sample({
  clock: AuthProviderGate.open,
  target: getSession
})

export const $isAuthState = createStore(false);

sample({
  clock: fxGetSession.fail,
  fn: () => true,
  target: $isAuthState,
})

sample({
  clock: fxLogin.doneData,
  fn: () => false,
  target: $isAuthState,
})

sample({
  clock: fxGetSession.fail,
  source: AuthProviderGate.state,
  fn: (state) => {
    return state
  },
  target: login,
})