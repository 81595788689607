import { createEffect, createEvent, createStore, restore, sample } from "effector";
import { authService, ILoginReq, IReqUser, IUser } from "shared/api";

export const fxGetSession = createEffect<void, IUser>((params) => {
  return authService.session();
})

export const fxLogin = createEffect<ILoginReq, IUser>((params) => {
  return authService.login(params);
})

export const $session = restore(fxGetSession.doneData, null);

$session.watch(data => {
  console.log('$session', data)
})

export const login = createEvent<ILoginReq>();
export const getSession = createEvent();

sample({
  clock: login,
  target: fxLogin
})

sample({
  clock: getSession,
  target: fxGetSession
})

sample({
  clock: fxLogin.doneData,
  fn: (data) => {
    return data
  },
  target: $session
})