import React from 'react';
import styled from "styled-components";
import { useForm } from "effector-forms";
import { filterForm } from "./model";
import { TaskStatus } from "shared/api";

export const Filter = () => {
  const {fields: { status }} = useForm(filterForm)

  return (
    <FilterWrap>
      <div className={status.value === TaskStatus.TODO ? "selected" : ""} onClick={() => status.onChange(TaskStatus.TODO)}>Сделать</div>
      <div className={status.value === TaskStatus.IN_PROGRESS ? "selected" : ""} onClick={() => status.onChange(TaskStatus.IN_PROGRESS)}>В работе</div>
      <div className={status.value === TaskStatus.REVIEW ? "selected" : ""} onClick={() => status.onChange(TaskStatus.REVIEW)}>На проверке</div>
    </FilterWrap>
  );
};

const FilterWrap = styled.div`
    display: flex;
    gap: var(--spacing--03);
    width: 100%;
    overflow-x: auto;
    margin: 0 calc(-1 * var(--spacing--05));
    padding: 0 var(--spacing--05);
    
    div {
        padding: var(--spacing--03) var(--spacing--05);
        font-size: 17px;
        font-weight: 500;
        line-height: 22px;
        color: rgba(60, 60, 67, 0.60);
        border-radius: 20px;
        white-space: nowrap;
        cursor: pointer;
        
        &.selected {
            color: #000;
            background: #E5E5EA;
        }
    }
`