import { createEffect, createStore, sample } from "effector";
import { IReqTasks, ITask, taskService } from "shared/api";
import { $session } from "entities/user";

export const fxGetTasks = createEffect<IReqTasks, ITask[]>((params = {}) => {
  return taskService.findAll(params);
})

export const $taskAll = createStore<ITask[]>([]);
export const $userTaskAll = createStore<ITask[]>([]);
export const $taskAllPending = fxGetTasks.pending;

sample({
  clock: fxGetTasks.doneData,
  source: $session,
  fn: (session, data) => {
    return data.filter((item) => item.assignedToId !== session?.id)
  },
  target: $taskAll
})

sample({
  clock: fxGetTasks.doneData,
  source: $session,
  fn: (session, data) => {
    return data.filter((item) => item.assignedToId === session?.id)
  },
  target: $userTaskAll
})